import {type MetafieldType} from '~/types/shared';

export const DEFAULT_PAGINATION_LIMIT = 50;

// We don't simply want "Search & Discovery" which comes back from gql.
export const APP_NAME = 'Shopify Search & Discovery';
export const APP_NAME_SHORT = 'Search & Discovery';

// Appended pathnames from the App Extension UI of the Partners Dashboard
export const APP_EXTENSION = {
  boostsProductDetails: 'appExtension',
  recosProductDetails: 'appExtension',
} as const;
const appExtensionValues = Object.values(APP_EXTENSION);
export type AppExtensionPath = (typeof appExtensionValues)[number];

// IMPORTANT TO NOTE the following rules due to this map being used in gql mutations & queries:
// 1. Don't use dots or dashes in the value
// 2. Don't use PascalCase, use camelCase for the keys
export const DISMISSABLE_CARDS_MAP = {
  overviewFeedbackCard: 'Discovery_app_Overview_FeedbackCard',
  overviewFilterSortCard: 'Discovery_app_Overview_FilterSortCard',
} satisfies Record<string, `Discovery_app_${string}`>;

type DismissableCardsMap = typeof DISMISSABLE_CARDS_MAP;
export type DismissableCardHandle =
  DismissableCardsMap[keyof DismissableCardsMap];

/**
 * This data structure represents a collection of metafields, all of which are grouped by their namespace.
 */
type MetaFieldDefinitions = Record<
  // The top-level index signature represents a namespace, like "ProductBoosts", and has that namespace's metafields under it
  string,
  {
    namespace: string;
    metafields: Record<
      // This index signature level represents a metafield and uses names like "Queries"
      string,
      {
        key: string;
        type: MetafieldType;
      }
    >;
  }
>;

/**
 * This data structure represents a collection of metaobjects, all of which are grouped by the metaobject itself.
 */
type MetaObjectDefinitions = Record<
  // The top-level index signature represents a metaobject. It will have a type and all metafields associated with that metaobject
  string,
  {
    type: string;
    metafields: Record<
      string,
      {
        key: string;
        type: MetafieldType;
      }
    >;
  }
>;

export const MetafieldDefinitions = {
  ProductBoosts: {
    namespace: 'shopify--discovery--product_search_boost',
    metafields: {
      Queries: {
        key: 'queries',
        type: 'list.single_line_text_field',
      },
    },
  },
  ProductRecommendations: {
    namespace: 'shopify--discovery--product_recommendation',
    metafields: {
      RelatedProducts: {
        key: 'related_products',
        type: 'list.product_reference',
      },
      RelatedProductsDisplay: {
        key: 'related_products_display',
        type: 'single_line_text_field',
      },
      ComplementaryProducts: {
        key: 'complementary_products',
        type: 'list.product_reference',
      },
    },
  },
} satisfies MetaFieldDefinitions;

export const MetaObjectDefinitions = {
  SynonymGroup: {
    type: 'synonym_group',
    metafields: {
      Title: {
        key: 'title',
        type: 'single_line_text_field',
      },
      Synonyms: {
        key: 'synonyms',
        type: 'list.single_line_text_field',
      },
    },
  },
} satisfies MetaObjectDefinitions;

export const thumbnailSizeSmall = {width: 80, height: 80};

export const thumbnailSizeExtraLarge = {width: 320, height: 320};

export const EXPECTED_LOCALES = [
  'en',
  'cs',
  'da',
  'de',
  'es',
  'fi',
  'fr',
  'it',
  'ja',
  'ko',
  'nb',
  'nl',
  'pl',
  'pt-BR',
  'pt-PT',
  'sv',
  'th',
  'tr',
  'vi',
  'zh-CN',
  'zh-TW',
] as const;
export type ExpectedLocalesType = (typeof EXPECTED_LOCALES)[number];
